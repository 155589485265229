import * as React from "react";
import styled from "@emotion/styled";
import {
  Grid,
  List,
  ListItemText as MuiListItemText,
  ListItemButton as MuiListItemButton,
  Stack,
} from "@mui/material";
import preval from "preval.macro";
import SidebarFooter from "./sidebar/SidebarFooter";
import moment from "moment";

const Wrapper = styled.div`
  ${(props) => props.theme.spacing(0)};
  padding: ${(props) => props.theme.spacing(0)};
  background: ${(props) => props.theme.footer.background};
  position: relative;
`;

const ListItemButton = styled(MuiListItemButton)`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)};
  padding-right: ${(props) => props.theme.spacing(2)};

  &,
  &:hover,
  &:active {
    color: #ff0000;
  }
`;

const ListItemText = styled(MuiListItemText)`
  span {
    color: ${(props) => props.theme.footer.color};
  }
`;

function Footer() {
  const info = JSON.parse(localStorage.getItem("user"));
  const buildTime = preval`module.exports = new Date();`;
  return (
    <Wrapper>
      <Grid container spacing={0}>
        <Grid
          sx={{ display: { xs: "none", md: "block" } }}
          container
          item
          xs={12}
          md={6}
        >
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            {(info?.user?.group_id === -1 || info?.user?.group_id === -6) && (
              <SidebarFooter />
            )}
            <List sx={{ ml: 2 }}>
              <ListItemButton sx={{ mr: 2 }}>
                <ListItemText primary="Version 1.1.30" />
              </ListItemButton>
              <ListItemButton>
                <ListItemText
                  primary={`Build time: ${moment(buildTime).format(
                    "DD.MM.YYYY HH:mm:ss"
                  )}`}
                />
              </ListItemButton>
            </List>
          </Stack>
        </Grid>
        <Grid container item xs={12} md={6} justifyContent="flex-end">
          <List sx={{ mr: 2 }}>
            <ListItemButton>
              <ListItemText
                primary={`Copyright © Lochin Ko’z Systems - ${new Date().getFullYear()} `}
              />
            </ListItemButton>
          </List>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Footer;
