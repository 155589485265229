const en = {
  language: "Language",
  notifications: "Notifications",
  account: "Account",
  profile: "Profile",
  Apps: "Apps",
  Version: "Version",
  "sign out": "Sign out",
  "sign in": "Sign In",
  Welcome: "Welcome",
  "Modified date": "Modified date",
  "Sign in to your account to continue": "Sign in to your account to continue",
  Password: "Password",
  Login: "Login",
  "Sign up": "Sign up",
  "To register in the system, please call number":
    "To register in the system, please call number  +998 (99) 999 99 99.",
  day: "Day",
  night: "Night",
  "Page not found.": "Page not found.",
  "The page you are looking for might have been removed.":
    "The page you are looking for might have been removed.",
  "Return to website": "Return to website",
  "Welcome back": "Welcome back",
  lang: "en-gb",
  Dashboard: "Dashboard",
  "Something went wrong": "Something went wrong",
  "Login is required": "Login is required",
  "Password is required": "Password is required",
  "Log Out": "Log Out",
  "Do you really want to log out": "Do you really want to log out",
  Yes: "Yes",
  No: "No",
  Accept: "Accept",
  Reject: "Reject",
  Verification: "Verification",
  "Confirmation of the fine": "Confirmation of the fine",
  "Do you really confirm this fine": "Do you really confirm this fine",
  "Information about the fine": "Information about the fine",
  Date: "Date",
  "Fine type": "Fine type",
  Visual: "Visual",
  "Check number": "Check number",
  Location: "Location",
  Image: "Photo",
  Video: "Video",
  Size: "Size",
  "No data for verification": "No data for verification",
  "No video": "No video",
  "Do you really reject this fine": "Do you really reject this fine",
  Canceled: "Canceled",
  Confirmed: "Confirmed",
  Search: "Search",
  Map: "Map",
  online: "online",
  Online: "Online",
  offline: "offline",
  Offline: "Offline",
  Settings: "Settings",
  Entrepreneurs: "Entrepreneurs",
  Add: "Add",
  Update: "Update",
  Directory: "Directory",
  Name: "Name",
  INN: "STIR",
  "Created Date": "Created Date",
  "Expiration Date": "Expiration Date",
  Status: "Status",
  Actions: "Actions",
  "Add Entrepreneur": "Add an entrepreneur",
  Edit: "Edit",
  maximum: "The maximum number of characters must not exceed {{num}}",
  Required: "Required",
  Delete: "Delete",
  Cancel: "Cancel",
  "Remove entrepreneur?": "Remove entrepreneur?",
  "Are you sure you want to delete the?":
    "Are you sure you want to delete the {{name}}?",
  "Successfully edited": "Successfully edited",
  "Successfully added": "Successfully added",
  Removed: "Removed",
  All: "All",
  "Less than 30 days left before contract expires":
    "Less than 30 days left before contract expires",
  "Field must consist  9 or 14 numbers": "Field must consist  9 or 14 numbers",
  "Date must be greater than this date":
    "Date must be greater than today's date",
  Objects: "Objects",
  Object: "Object",
  Users: "Users",
  Username: "Username",
  "Full name": "Full name",
  Description: "Description",
  "Phone number": "Phone number",
  "Last seen": "Last seen",
  "Add User": "Add User",
  "Add Object": "Add Object",
  min: "At least {{num}} characters",
  Region: "Region",
  Address: "Address",
  "Device types": "Device type",
  Rules: "Rule types",
  "Add device type": "Add device type",
  "Add rule": "Add rule",
  "Only numbers": "Only numbers",
  "Device type": "Device type",
  "Last update": "Last update",
  Copy: "Copy",
  Device: "Device",
  "Add Computer": "Add Computer",
  "Invalid IP address format": "Invalid IP address format",
  "Please add the location on the map": "Please add the location on the map",
  Computers: "Computers",
  "Add Camera": "Add Camera",
  "No data": "No data",
  "Car number": "Car number",
  Dislocation: "Dislocation",
  Rule: "Rule",
  DRB: "Car number",
  From: "From",
  To: "To",
  Cameras: "Cameras",
  Comment: "Comment",
  Code: "Code",
  "Open in telegram": "Open in telegram",
  "Search by ID": "Search by ID",
  Information: "Information",
  paginationFooter: "Total: {{num}} out of {{num1}}",
  View: "View",
  Restore: "Restore",
  "Enter your password": "Enter your password",
  Successfully: "Successfully",
  Staff: "Staff",
  Administrators: "Administrators",
  Operators: "Operators",
  Source: "Source",
  "Select user from NTLM": "Select user from NTLM",
  "Fill the form": "Fill the form",
  Next: "Next",
  Back: "Back",
  Skip: "Skip",
  Optional: "Optional",
  Devices: "Devices",
  "Devices (V2)": "Devices (V2)",
  "Incorrect value": "Incorrect value",
  "Control (Police)": "Control (Police)",
  Control: "Control",
  "Change rule": "Change rule",
  Permissions: "Permissions",
  "Enter a new title": "Enter a new title",
  Stop: "Stop",
  "YPX users": "Users (Police)",
  "The time the statistics were taken": "The time the statistics were taken",
  Today: "Today",
  Yesterday: "Yesterday",
  Difference: "Difference",
  Violations: "Violations",
  "Cars in joint movement": "Cars in joint movement",
  Speed: "Speed",
  "Stats by rules": "Stats by rules",
  "Statistics of violation types by date":
    "Statistics of violation types by date",
  "Operator stats": "Operator stats",
  Appmanager: "App Manager",
  FileManager: "File Manager",
  UploadFile: "Upload file",
  CreateFolder: "Create folder",
  "Data not found": "Data not found",
  "Add API": "Add API",
  Bonus: "Bonus",
  "Bonus active": "Bonus active",
  "Bonus passive": "Bonus passive",
  "Location not found": "The location was not determined",
  Total: "Total",
  sum: "sum",
  "Active devices": "Active",
  "Warning devices": "Warning",
  "Error devices": "Error",
  "Disabled by admin": "Disabled by admin",
  "Disabled devices": "Disabled devices",
  YPX: "Police",
  Moderators: "Moderators",
  Engineers: "Technical engineers",
  Workers: "Technical workers",
  Worker: "Technical worker",
  "Waiting status": "Waiting status",
  "Sending status": "Sending status",
  "Sent status": "Sent status",
  "Rejected status": "Rejected status",
  "Passive status": "Passive status",
  "Canceled status": "Canceled status",
  Test: "Test",
  Sending: "Sending",
  Rejected: "Rejected",
  Sent: "Sent",
  Resend: "Resend",
  "Access to control": "Access to control",
  "Access to verification": "Access to verification",
  "Access to search": "Access to search",
  "Verification objects": "Verification objects",
  "Control objects": "Control objects",
  Uptime: "Uptime",
  Infos: " Infos",
  Errors: "Errors",
  Memory: "Memory",
  free: "free",
  used: "used",
  CPU: "CPU",
  Network: "Network",
  Disc: "Disc",
  "Cancel send": "Cancel send",
  Maintain: "Maintain",
  "Change role": "Change role",
  Administrator: "Administrator",
  Boss: "Boss",
  Moderator: "Moderator",
  Operator: "Operator",
  Engineer: "Engineer",
  "Manage Operators": "Manage Operators",
  "Time interval": "Time interval",
  Monday: "Monday",
  Tuesday: "Tuesday",
  Wednesday: "Wednesday",
  Thursday: "Thursday",
  Friday: "Friday",
  Saturday: "Saturday",
  Sunday: "Sunday",
  "Certificate Date": "Certificate Date",
  "Advanced search filters": "Advanced search filters",
  "Reason for rejection": "Reason for rejection",
  "Influence of an external factor": "Influence of an external factor",
  "The number is invalid": "The number is invalid",
  "The number is not specified": "The number is not specified",
  "The rule is not broken": "The rule is not broken",
  Message: "Message",
  Type: "Type",
  Brand: "Brand",
  Model: "Model",
  Color: "Color",
  Close: "Close",
  "Working hours": "Working hours",
  Constructor: "Constructor",
  "LK uptime": "LK uptime",
  Sender: "Sender",
  "Sender uptime": "Sender uptime",
  Storage: "Storage",
  "System uptime": "System uptime",
  Short: "Short",
  Detailed: "Detailed",
  Exit: "Exit",
  "Copy table": "Copy table",
  "Table copied": "Table copied",
  ago: "ago",
  "day time": "day",
  days: "days",
  hour: "hour",
  hours: "hours",
  minute: "minute",
  minutes: "minutes",
  second: "second",
  seconds: "seconds",
  "Change entrepreneur": "Change entrepreneur",
  "Change computer": "Change computer",
  "Change object": "Change object",
  Table: "Table",
  Chart: "Chart",
  Report: "Report",
  Lines: "Lines",
  Polygons: "Polygons",
  Points: "Points",
  Clear: "Clear",
  Send: "Send",
  Save: "Save",
  Line: "Line",
  DoubleLine: "DoubleLine",
  "Stop Line": "Stop Line",
  "Stop sign": "Stop sign",
  "Red Polygon": "Red Polygon",
  "Speed Polygon": "Speed Polygon",
  "Traffic light": "Traffic light",
  "Add Region": "Add region",
  Regions: "Regions",
  Province: "Province",
  "Update photo": "Update photo",
  Warnings: "Warnings",
  Snapshots: "Snapshots",
  "Operators statistics": "Operators statistics",
  Configurator: "Configurator",
  " Do you want save the changes you made to Configurator ?":
    " Do you want save the changes you made to Configurator ?",
  "Your changes will be lost if you don't save them!":
    "Your changes will be lost if you don't save them!",
  "Don't Save": "Don't Save",
  Monitoring: "Monitoring",
  Radar: "Radar",
  Crossroads: "Crossroads",
  "Last rule": "Last rule",
  "Last registration": "Last registration",
  "Last received data": "Last received data",
  "Never worked": "Never worked",
  "No contact": "No contact",
  "Everything is fine": "Everything is fine",
  "No violation": "No violation",
  "No information": "No information",
  "Recive/Send": "Recive/Send",
  Comments: "Comments",
  Action: "Action",
  "Turn on": "Turn on",
  "Turn off": "Turn off",
  "Operator report": "Operator report",
  Active: "Active",
  Passive: "Passive",
  "Rules for verify": "Rules for verify",
  "Rules for no verify": "Rules for no verify",
  "User info": "User info",
  Content: "Content",
  Telegram: "Telegram",

  "Speed limit for trucks": "Speed limit for trucks",
  "Speed limit for buses": "Speed limit for buses",
  Entrepreneur: "Entrepreneur",
  "Works at night": "Works at night",
  "Accessed date": "Accessed date",
  "Sended date": "Sended date",
  "Report by camera (Violations)": "Report by camera (Violations)",
  Range: "Range",
  Weekly: "Weekly",
  Monthly: "Monthly",
  Daily: "Daily",
  "Number of violations": "Number of violations",
  "Show all": "Show all",
  "Read all": "Read all",
  "No new notifications": "No new notifications",
  "New notifications": "New notifications",
  "Operator Logs": "Operator Logs",
  New: "New",
  Old: "Old",
  videoViewAlert:
    "The video of the violation has not been viewed in its entirety",
  "Choose an entrepreneur": "Choose an entrepreneur",
  "Wrong format": "Wrong format",
  "Add car number": "Add car number",
};

export default en;
