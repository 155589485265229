const uzCr = {
  language: "Тил",
  notifications: "Билдиришномалар",
  account: "Аккаунт",
  profile: "Профил",
  Apps: "Иловалар",
  Version: "Версия",
  "Modified date": "Ўзгартирилган сана",
  "sign out": "Тизимдан чиқиш",
  "sign in": "Тизимга кириш",
  Welcome: "LK тизимига ҳуш келибсиз",
  "Sign in to your account to continue":
    "Давом этиш учун аккаунтингизга киринг",
  Password: "Парол",
  Login: "Логин",
  "Sign up": "Рўйхатдан ўтиш",
  "To register in the system, please call number":
    "Тизимда рўйхатдан ўтиш учун +998 (99) 999 99 99 рақамига мурожаат қилинг.",
  day: "Кун",
  night: "Тун",
  "Page not found.": "Саҳифа топилмади.",
  "The page you are looking for might have been removed.":
    "Сиз қидираётган саҳифа ўчирилган бўлиши мумкин.",
  "Return to website": "Веб-сайтга қайтиш",
  "Welcome back": "Хуш келибсиз",
  lang: "uz",
  Dashboard: "Дашборд",
  "Something went wrong": "Кутилмаган хатолик юз берди",
  "Login is required": "Логин талаб қилинади",
  "Password is required": "Парол талаб қилинади",
  "Log Out": "Чиқиш",
  "Do you really want to log out": "Ҳақиқатан ҳам тизимдан чиқмоқчимисиз",
  Yes: "Ха",
  No: "Йўқ",
  Accept: "Тасдиқлаш",
  Reject: "Рад қилиш",
  Verification: "Тасдиқлаш",
  "Confirmation of the fine": "Жаримани тасдиқлаш",
  "Do you really confirm this fine": "Ҳақиқатан ҳам бу жаримани тасдиқлайсизми",
  "Information about the fine": "Жарима ҳақида маълумот",
  Date: "Сана",
  "Fine type": "Ҳуқуқбузарлик тури",
  Visual: "Визуал",
  "Check number": "Рақамни текшириш",
  Location: "Жойлашув",
  Image: "Фото",
  Video: "Видео",
  Size: "Ҳажми",
  "No data for verification": "Тасдиқлаш учун маълумотлар йўқ",
  "No video": "Видео йўқ",
  "Do you really reject this fine":
    "Сиз ҳақиқатан ҳам бу жаримани рад қиласизми",
  Canceled: "Бекор қилинди",
  Confirmed: "Тасдиқланди",
  Search: "Қидириш",
  Map: "Харита",
  online: "онлайн",
  Online: "Oнлайн",
  offline: "офлайн",
  Offline: "Офлайн",
  Settings: "Созламалар",
  Entrepreneurs: "Тадбиркорлар",
  Add: "Қўшиш",
  Update: "Янгилаш",
  Directory: "Справочник",
  Name: "Номи",
  INN: "СТИР",
  "Personal Information": "Шахсий маълумот",
  "Created Date": "Яратилган сана",
  "Expiration Date": "Тугаш санаси",
  Status: "Статус",
  Actions: "Амаллар",
  "Add Entrepreneur": "Тадбиркор қўшиш",
  Edit: "Таҳрирлаш",
  maximum: "Белгиларнинг сони {{num}} дан ошмаслиги керак",
  Required: "Тўлдириш талаб қилинади",
  Delete: "Ўчириш",
  Cancel: "Бекор қилиш",
  "Remove entrepreneur?": "Тадбиркор ўчирилсинми?",
  "Are you sure you want to delete the?":
    "Ҳақиқатан ҳам {{name}} ни ўчирмоқчимисиз?",
  "Successfully edited": "Муваффақиятли таҳрирланди",
  "Successfully added": "Муваффақиятли қўшилди",
  Removed: "Ўчирилди",
  All: "Ҳаммаси",
  "Less than 30 days left before contract expires":
    "Шартнома муддати тугашига 30 кундан кам вақт қолган",
  "Field must consist  9 or 14 numbers":
    "Майдон 9 ёки 14та рақамдан иборат бўлиши керак",
  "Date must be greater than this date":
    "Сана бугунги санадан катта бўлиши керак",
  Objects: "Объектлар",
  Object: "Объект",
  Users: "Фойдаланувчилар",
  Username: "Логин",
  Email: "Электрон Почта",
  "Full name": "Тўлиқ исми",
  "First Name": "Фамиля",
  "Last Name": "Исм",
  Description: "Тавсиф",
  "Phone number": "Телефон рақами",
  "Last seen": "Охирги ташриф",
  "Add User": "Фойдаланувчи қўшиш",
  "Add Object": "Объект қўшиш",
  min: "Камида {{num}}та белги бўлсин",
  Region: "Ҳудуд",
  Address: "Манзил",
  "Device types": "Қурилма турлари",
  Rules: "Қоида турлари",
  "Add device type": "Қурилма турини қўшиш",
  "Add rule": "Қоида қўшиш",
  "Only numbers": "Фақат рақамлар",
  "Device type": "Қурилма тури",
  "Last update": "Охирги янгиланиш",
  Copy: "Нусха кўчириш",
  Device: "Қурилма",
  "Add Computer": "Компьютер қўшиш",
  "Invalid IP address format": "IP-адрес формати нотўғри",
  "Please add the location on the map": "Илтимос, харитада жойлашувни қўшинг",
  Computers: "Компьютерлар",
  "Add Camera": "Камера қўшиш",
  "No data": "Маълумот йўқ",
  "Car number": "Автомобиль рақами",
  Dislocation: "Дислокация",
  Rule: "Қоида",
  DRB: "ДРБ",
  From: "Дан",
  To: "Гача",
  Cameras: "Камералар",
  Comment: "Изоҳ",
  Code: "Код",
  "Open in telegram": "Телеграмда очинг",
  "Search by ID": "ID бўйича қидириш",
  Information: "Маълумот",
  paginationFooter: "Жами: {{num1}} тадан {{num}} та",
  View: "Кўриш",
  Restore: "Қайта тиклаш",
  "Enter your password": "Паролингизни киритинг",
  Successfully: "Муваффақиятли",
  Staff: "Ходимлар",
  Administrators: "Администраторлар",
  Operators: "Операторлар",
  Source: "Манба",
  "Select user from NTLM": "NTLM дан фойдаланувчини танланг",
  "Fill the form": "Шаклни тўлдиринг",
  Next: "Кейинги",
  Back: "Орқага",
  Skip: "Ўтказиб юбориш",
  Optional: "Ихтиёрий",
  Devices: "Қурилмалар",
  "Devices (V2)": "Қурилмалар (V2)",
  "Incorrect value": "Нотўғри қиймат",
  "Control (Police)": "Назорат (ЙПХ)",
  Control: "Назорат",
  "Change rule": "Қоидани ўзгартириш",
  Permissions: "Рухсатномалар",
  "Enter a new title": "Янги ном киритинг",
  Stop: "Тўхтатиш",
  "YPX users": "Фойдаланувчилар (ЙПХ)",
  "The time the statistics were taken": "Статистик маълумотлар олинган вақт",
  Today: "Бугун",
  Yesterday: "Кеча",
  Difference: "Фарқ",
  Violations: "Қоидабузарликлар",
  "Cars in joint movement": "Бир йўналишда ҳаракатдаги машиналар",
  Speed: "Тезлик",
  "Stats by rules": "Қоидалар бўйича статистика",
  "Statistics of violation types by date":
    "Саналар бўйича қоидабузарлик турлари статистикаси",
  "Operator stats": "Операторлар статистикаси",
  Appmanager: "Илова менежери",
  FileManager: "Файл бошқаруви",
  UploadFile: "Файлни юклаш",
  CreateFolder: "Папка яратиш",
  "Data not found": "Маълумот топилмади",
  "Add API": "API қўшиш",
  Bonus: "Бонус",
  "Bonus active": "Бонус актив",
  "Bonus passive": "Бонус пассив",
  "Location not found": "Жойлашув аниқланмади",
  Total: "Жами",
  sum: "сўм",
  "Active devices": "Тўлиқ иш ҳолатида",
  "Warning devices": "Қисман носозлик мавжуд",
  "Error devices": "Маълумот юборишда ҳатолик мавжуд",
  "Disabled by admin": "Администратор томонидан ўчирилган",
  "Disabled devices": "Ўчиқ қурилмалар",
  YPX: "ЙПХ",
  Moderators: "Модераторлар",
  Engineers: "Техник муҳандислар",
  Workers: "Техник ходимлар",
  Worker: "Техник ходим",
  "Waiting status": "Тасдиқланмаган",
  "Sending status": "Тасдиқланган",
  "Sent status": "Қабул қилинган қоидабузарликлар",
  "Rejected status": "Қайтган қоидабузарликлар",
  "Passive status": "Бекор қилинганлар",
  "Canceled status": "Юбориш бекор қилинганлар",
  Test: "Тест",
  Sending: "Юборилмоқда",
  Rejected: "Рад қилинди",
  Sent: "Юборилди",
  Resend: "Қайта юбориш",
  "Access to control": "Назоратга рухсат",
  "Access to verification": "Тасдиқлашга рухсат",
  "Access to search": "Қидиришга рухсат",
  "Verification objects": "Тасдиқлаш объектлари",
  "Control objects": "Назорат объектлари",
  Uptime: "Иш вақти",
  Infos: "Маълумотлар",
  Errors: "Хатолар",
  Memory: "Хотира",
  free: "Бўш",
  used: "Ишлатилган",
  CPU: "Марказий процессор",
  Network: "Тармоқ",
  Disc: "Диск",
  " KBps": "Кбит/с",
  " Mbps": "Мбит/с",
  " Gbps": "Гбит/с",
  TX: "TX",
  RX: "РХ",
  "Cancel send": "Юборишни бекор қилиш",
  Maintain: "Маинтаин",
  "Change role": "Ролни ўзгартириш",
  Administrator: "Администратор",
  Boss: "Босс",
  Moderator: "Модератор",
  Operator: "Оператор",
  Engineer: "Техник муҳандис",
  "Manage Operators": "Операторларни бошқариш",
  "Time interval": "Вақт оралиғи",
  Monday: "Душанба",
  Tuesday: "Сешанба",
  Wednesday: "Чоршанба",
  Thursday: "Пайшанба",
  Friday: "Жума",
  Saturday: "Шанба",
  Sunday: "Якшанба",
  "Certificate Date": "Сертификат санаси",
  "Advanced search filters": "Кенгайтирилган қидирув фильтрлари",
  "Reason for rejection": "Рад қилиш сабаби",
  "Influence of an external factor": "Ташқи омил таъсири",
  "The number is invalid": "ДРБ яроқсиз",
  "The number is not specified": "ДРБ аниқланмаган",
  "The rule is not broken": "Қоида бузилмаган",
  Message: "Хабар",
  Type: "Тури",
  Brand: "Бренд",
  Model: "Модель",
  Color: "Ранги",
  Close: "Ёпиш",
  "Working hours": "Иш вақти",
  Constructor: "Конструктор",
  "LK uptime": "LK иш вақти",
  Sender: "Юборувчи",
  "Sender uptime": "Юборувчининг иш вақти",
  Storage: "Ҳотира омбори",
  "System uptime": "Тизимнинг иш вақти",
  Short: "Қисқача",
  Detailed: "Батафсил",
  Exit: "Чиқиш",
  "Copy table": "Жадвални нусхалаш",
  "Table copied": "Жадвал нусхаланди",
  ago: "олдин",
  "day time": "кун",
  days: "кун",
  hour: "соат",
  hours: "соат",
  minute: "минут",
  minutes: "минут",
  second: "секунд",
  seconds: "секунд",
  "Change entrepreneur": "Тадбиркорни алмаштириш",
  "Change computer": "Компьютерни алмаштириш",
  "Change object": "Объектни алмаштириш",
  Table: "Жадвал",
  Chart: "График",
  Report: "Ҳисобот",
  Lines: "Чизиқлар",
  Polygons: "Кўпбурчаклар",
  Points: "Нуқталар",
  Clear: "Тозалаш",
  Send: "Юбориш",
  Save: "Сақлаш",
  Line: "Чизиқ",
  DoubleLine: "Икки чизиқ",
  "Stop Line": "Тўхташ чизиғи",
  "Stop sign": "Тўхташ белгиси",
  "Red Polygon": "Қизил кўпбурчак",
  "Speed Polygon": "Тезлик кўпбурчаги",
  "Traffic light": "Светофор",
  "Add Region": "Ҳудуд қўшиш",
  Regions: "Ҳудудлар",
  Province: "Вилоят",
  "Update photo": "Суратни янгилаш",
  Warnings: "Огоҳлантиришлар",
  Snapshots: "Снепшотлар",
  Configurator: "Конфигуратор",
  "Operators statistics": "Операторлар статистикаси",
  " Do you want save the changes you made to Configurator ?":
    "Конфигураторга киритилган ўзгаришларни сақламоқчимисиз?",
  "Your changes will be lost if you don't save them!":
    "Агар сиз уларни сақламасангиз, ўзгартиришларингиз йўқолади!",
  "Don't Save": "Сақланмасин",
  Monitoring: "Мониторинг",
  Radar: "Радар",
  Crossroads: "Чорраҳа",
  "Last rule": "Охирги қоида бузилиши",
  "Last registration": "Сўнгги рўйхатдан ўтиш",
  "Last received data": "Охирги олинган маълумотлар",
  "Never worked": "Бирор марта ишламаган",
  "No contact": "Алоқа йўқ",
  "Everything is fine": "Ҳаммаси яхши",
  "No violation": "Қоидабузарлик йўқ",
  "No information": "Маълумот йўқ",
  "Recive/Send": "Қабул қилиш/Юбориш",
  Comments: "Изоҳлар",
  Action: "Ҳаракат",
  "Turn on": "Ёқиш",
  "Turn off": "Ўчириш",
  "Operator report": "Оператор ҳисоботи",
  Active: "Актив",
  Passive: "Пассив",
  "Rules for verify": "Тасдиқлаш учун қоидалар",
  "Rules for no verify": "Тасдиқланмайдиган қоидалар",
  "User info": "Фойдаланувчи маълумотлари",
  Content: "Мазмуни",
  Telegram: "Телеграм",
  "Speed limit for trucks": "Юк машиналари учун тезлик чегараси",
  "Speed limit for buses": "Автобуслар учун тезлик чегараси",
  Entrepreneur: "Предприниматель",
  "Works at night": "Кечаси ишлайди",
  "Accessed date": "Кириш санаси",
  "Sended date": "Юборилган сана",
  "Report by camera (Violations)": "Камера ҳисоботи (Қоидабузарликлар)",
  Range: "Диапазон",
  Weekly: "Ҳафталик",
  Monthly: "Ойлик",
  Daily: "Кундалик",
  "Number of violations": "Қоидабузарликлар сони",

  "Show all": "Ҳаммасини кўриш",
  "Read all": "Кўрилди",
  "No new notifications": "Янги билдиришномалар йўқ",
  "New notifications": "Янги билдиришномалар сони",
  "Operator Logs": "Оператор Логлари",
  New: "Янги",
  Old: "Эски",
  videoViewAlert: "Қоидабузарлик акс этган видеоси тўлиқ кўрилмаган",
  "Choose an entrepreneur": "Тадбиркорлардан бирини танланг",
  "Wrong format": "Нотўғри формат",
  "Add car number": "ДРБ қўшиш",
};

export default uzCr;
